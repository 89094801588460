import React from 'react';
import Layout from '../../components/Layout';
import NavThree from '../../components/NavThree';
import PageHeader from '../../components/SchoolHeader';
import Footer from '../../components/Footer';
import ProgramsList from '../../components/ProgramsList';

const MedPsy = () => {
  return (
    <Layout pageTitle='School of Medical And Psychiatry Social Work | LUTH'>
      <NavThree />
      <PageHeader title='School of Medical And Psychiatric Social Work' />
      <ProgramsList name='School of Medical And Psychiatric Social Work' />
      <Footer />
    </Layout>
  );
};

export default MedPsy;
